import Vue from "vue";
import App from "./App.vue";
import router from "./router"; //路由
import "@/assets/css/reset.css"; //初始化css
import VueTouch from "vue-touch"; //监听手势
import { Lazyload } from "vant";
import "lib-flexible";
import VueClipboard from "vue-clipboard2";

import { Tab, Tabs, NoticeBar } from "vant";

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NoticeBar);

Vue.config.productionTip = false;
Vue.use(VueClipboard);

Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(VueTouch, { name: "v-touch" });
new Vue({
  router,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
