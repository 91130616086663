<template>
  <!-- class="pcm-app" -->
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
};
</script>
<style lang="scss">
#app {
  font-size: 14px;
  height: 100%;
  background: #09050a;
  position: relative;
  color: #ffffff;
}
</style>
