<template>
  <div class="home">
    <pc v-if="isPC"></pc>
    <mobile v-else></mobile>
  </div>
</template>
<script>
import userAgent from "@/utils/userAgent";
import mobile from "./components/mobile";
import pc from "./components/pc";

export default {
  name: "home",
  components: {
    mobile,
    pc,
  },
  data() {
    return {
      os: "", // 操作系统
    };
  },
  computed: {
    isPC() {
      return this.os.isPc;
    },
  },
  created() {
    this.os = userAgent();
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
}
</style>
